import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import ProductCarousel from './ProductCarousel'

const RoleCarousel = () => {
  const images = useStaticQuery(graphql`
    query {
      flow: file(
        relativePath: { eq: "images/carousel-images/engineering-leaders.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      impact: file(
        relativePath: { eq: "images/carousel-images/engineering-managers.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      quality: file(
        relativePath: { eq: "images/carousel-images/software-engineers.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      collaboration: file(
        relativePath: { eq: "images/carousel-images/platform-teams.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      health: file(
        relativePath: { eq: "images/carousel-images/finance-teams.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }
    }
  `)

  return (
    <ProductCarousel
      items={[
        {
          id: 'flow',
          title: 'Engineering leaders',
          description:
            'Get immediate visibility into key engineering metrics across all groups and teams.',
          image: getImage(images.flow),
        },
        {
          id: 'impact',
          title: 'Engineering managers',
          description:
            'Proactively identify and fix teamwork bottlenecks as they occur.',
          image: getImage(images.impact),
        },
        {
          id: 'quality',
          title: 'Software engineers',
          description:
            'Get your code reviewed faster and never let PRs fall through the cracks again.',
          image: getImage(images.quality),
        },
        {
          id: 'collaboration',
          title: 'Platform teams',
          description:
            'Measure the impact of organization-wide productivity improvements.',
          image: getImage(images.collaboration),
        },
        {
          id: 'health',
          title: 'Finance teams',
          description:
            'Get reliable data for software cost capitalization reporting.',
          image: getImage(images.health),
        },
      ]}
    />
  )
}

export default RoleCarousel
